import * as allTypes from './types';

export function currentVideoRequest(
	video_session_id,
	slug,
	callbackSuccess,
	callbackError
) {
	return {
		type: allTypes.CURRENT_VIDEO,
		video_session_id,
		slug,
		callbackSuccess,
		callbackError,
	};
}

export function assetVideoRequest(
	id,
	slug,
	assetCallBackSuccess,
	assetCallBackError
) {
	return {
		type: allTypes.ASSET_VIDEO,
		id,
		slug,
		assetCallBackSuccess,
		assetCallBackError,
	};
}

// Reducers
export function updateCurrentVideo(data, callbackSuccess, callbackError) {
	return {
		type: allTypes.UPDATE_CURRENT_VIDEO,
		data,
		callbackSuccess,
		callbackError,
	};
}

export function updateAssetVideo(data, callbackSuccess, callbackError) {
	return {
		type: allTypes.UPDATE_ASSET_VIDEO,
		data,
		callbackSuccess,
		callbackError,
	};
}

export function trackingLogs(
	eventId,
	videoId,
	video_timestamp,
	uniqueSessionId
) {
	return {
		type: allTypes.VIDEO_TRACKING_LOGS,
		eventId,
		videoId,
		video_timestamp,
		uniqueSessionId,
	};
}

// Question and answer
export function submitQuestion(
	videoId,
	question,
	is_anonymous,
	slug,
	user,
	questionCallBackSuccess,
	questionCallBackError
) {
	return {
		type: allTypes.SUBMIT_QUESTION,
		videoId,
		question,
		is_anonymous,
		slug,
		user,
		questionCallBackSuccess,
		questionCallBackError,
	};
}

export function allQuestions(videoId, slug, callbackSuccess, callbackError) {
	return {
		type: allTypes.ALL_QUESTIONS,
		videoId,
		slug,
		callbackSuccess,
		callbackError,
	};
}

export function myQuestions(questions, videoId) {
	return {
		type: allTypes.MY_QUESTIONS,
		questions,
		videoId,
	};
}

export function updateQuestionState(videoId, question) {
	return {
		type: allTypes.UPDATE_QUESTION_STATE,
		videoId,
		question,
	};
}

export function getSessionPoll(videoId, slug, callbackSuccess, callbackError) {
	return {
		type: allTypes.GET_SESSION_POLL,
		videoId,
		slug,
		callbackSuccess,
		callbackError,
	};
}

export function submitPoll(
	pollId,
	choices,
	slug,
	callbackSuccess,
	callbackError
) {
	return {
		type: allTypes.SUBMIT_POLL,
		pollId,
		choices,
		slug,
		callbackSuccess,
		callbackError,
	};
}

export function toggleSidebar(id) {
	return {
		type: allTypes.TOGGLE_SIDEBAR,
		id,
	};
}

export function toggleVideo(id, value) {
	return {
		type: allTypes.TOGGLE_VIDEO,
		id,
		value,
	};
}

export function voteQuestion(
	rowId,
	voteState,
	slug,
	id,
	voteSuccess,
	voteError
) {
	return {
		type: allTypes.VOTE_QUESTION,
		rowId,
		voteState,
		slug,
		id,
		voteSuccess,
		voteError,
	};
}

export function getUserSessions(
	slug,
	session_id,
	callbackSuccess,
	callbackError
) {
	return {
		type: allTypes.GET_USER_SESSIONS,
		slug,
		session_id,
		callbackSuccess,
		callbackError,
	};
}

export function updateUserSessions(
	slug,
	session_block_id,
	session_id,
	submitSuccess,
	submitError
) {
	return {
		type: allTypes.UPDATE_USER_SESSIONS,
		slug,
		session_block_id,
		session_id,
		submitSuccess,
		submitError,
	};
}
