export const SpeakersData = [
	{
		name: 'ALAN OSTER - Group Chief Economist, NAB',
		photo: 'Alan.jpg',
		description:
			'Alan joined the Bank in 1992 from the Federal Treasury where he worked for 15 years - his special field being economic forecasting and monetary policy. He graduated (with first class honours) in economics from Newcastle University. He also holds a Masters degree in economics from the Australian National University.<br/><br/>Immediately before joining the Bank, Alan was the Senior Adviser in Treasury responsible for economic forecasting and modelling. In 1987 he was seconded for nearly four years as Counsellor-Economic and Financial with Australia’s delegation to the Organisation for Economic Co-operation and Development in Paris.<br/><br/>As Group Chief Economist, Alan is responsible for NAB’s global economic and financial forecasts. He is also a highly respected and much quoted commentator on Australian and global economic trends and policy issues.',
	},
	{
		name: 'Kate Galvin – Executive, Nab health',
		photo: 'Kate.jpg',
		description:
			'For the past 20 or so years, Kate’s passion for challenge and love of learning has seen her transition from the legal profession to investment banking and from wealth management to NAB.<br/><br/>Kate practiced as a commercial solicitor for 8 years before taking up executive roles within JBWere and Goldman Sachs JBWere. After leading teams in asset management and investment advice Kate moved to National Australia Bank as the General Manager for NAB Private, South. In the middle of 2018 Kate was appointed Customer Executive, NAB Health. In this role she is responsible for NAB’s offering to the Health sector via a national team of corporate and business bankers, Medfin finance and HICAPS. Kate’ s time in financial and professional services has given her a unique skill set to continue to build Australia’s best Health bank.<br/><br/>Kate has spent many years advocating for better and more diverse workplaces as the establishing Chair of the JBWere Diversity Council and Co-Chair of the Goldman Sachs Women’s Network. She has served on the Board of Sharegift Australia and currently sits on the Committee for MUMA – the Monash University Museum of Art.',
	},
	{
		name: 'DEAN PEARSON – Head of Behavioural economics, nab',
		photo: 'Dean.jpg',
		description:
			'Dean has over 20 years’ experience in analysing the economy and assessing the implications both in Australia and globally.<br/><br/>At NAB, Dean leads a team of analysts responsible for monitoring key business trends and identifying industries which are likely to provide the strongest growth opportunities and greatest risk.<br/><br/>Dean is a sought-after key note speaker at major business forums, with the ability to present complex information in a readily digestible and entertaining manner.',
	},
	{
		name: 'TRENT TWOMEY – NATIONAL PRESIDENT, Pharmacy guild of Australia',
		photo: 'Trent.jpg',
		description:
			'Trent Twomey has been involved with the Pharmacy Guild of Australia for over 10 years and was part of the negotiating team for the Sixth and Seventh Community Pharmacy Agreement. He was appointed National President in 2021.<br/><br/>Trent is an Adjunct Associate Professor at James Cook University and Director at Advance Cairns. He is also a past Chairman of the Northern Queensland Primary Health Network, past National President of the Australian College of Pharmacy Chairman of Advance Cairns, and member of the Trustee Committee for Economic Development of Australia.<br/><br/>Trent is an honorary life member of the National Australian Pharmacy Students’ Association and received the 2015 Outstanding Alumnist award from James Cook University School of Medicine and Dentistry. Trent and his wife own a small group of pharmacies in Northern Queensland.',
	},
];

export const AgendaData = [
	{
		name: 'NAB Federal Budget 2021 Insights for Metro Australia Customers',
		sessions: [
			{
				time: '9:00am',
				session: ['Nassim Khadem (MC) Welcome and Session Introduction'],
			},
			{
				time: '9:05am',
				session: ['Federal Budget 2021 economic analysis with Alan Oster'],
			},
			{
				time: '9:25am',
				session: [
					'Panel discussion with Michael Saadie, Sally Auld, Paul Abbey and Alan Oster, hosted Nassim Khadem',
				],
			},
			{
				time: '9:45am',
				session: ['Live Q&A with audience'],
			},
			{
				time: '10:00am',
				session: ['Event to conclude'],
			},
		],
	},
	{
		name: 'NAB Federal Budget 2021 Insights for Regional Australia and Agribusiness Customers',
		sessions: [
			{
				time: '11:00am',
				session: ['Nassim Khadem (MC) Welcome and Session Introduction'],
			},
			{
				time: '11:05am',
				session: ['Federal Budget 2021 economic analysis with Phin Ziebell'],
			},
			{
				time: '11:25am',
				session: [
					'Panel discussion with Julie Rynski, Sally Auld, Paul Abbey and Phin Ziebell, hosted by Nassim Khadem',
				],
			},
			{
				time: '11:45am',
				session: ['Live Q&A to commence'],
			},
			{
				time: '12:00pm',
				session: ['Event to Conclude'],
			},
		],
	},
];
