import React, { useState, useEffect, Suspense } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter, useParams } from 'react-router-dom';
import Switch from 'react-switch';
import SideDrawer from './SideDrawer';
import Container from 'react-bootstrap/Container';
import { togglePosition } from 'selectors/system';
import AlertSignal from './Components/Alert/AlertSignal';
import facebookOfficial from '@iconify-icons/fa/facebook-official';
import twitterSquare from '@iconify-icons/fa/twitter-square';
import instagramIcon from '@iconify-icons/fa/instagram';
import { Helmet } from 'react-helmet';
import { getSchemaName } from 'selectors/account';
import { sidebarToggler, videoToggler } from 'selectors/system';
import { pusherConfig } from 'helper/pusher';
import { getShowSideBarToggler, getVideoToggler } from 'selectors/session';
import { getPusherChannel } from 'config/Config';
import { toggleSidebar, toggleVideo } from 'actions/session';
import { getVideoPlayer } from 'actions/event';
import { ErrorToast } from 'components/ToastMessage/Toast';
import { getContentBlock } from 'selectors/event';
import SystemInfo from './SystemInfo';
import { Icon } from '@iconify/react';
import { getImageUrl, eventSlug } from 'helper/helper';

const VideoJS = React.lazy(() => import('./Player/VideoJS'));

const Watch = (props) => {
	let {
		toggleVideoContainer,
		toggleSidebar,
		toggleVideo,
		show_sidebar_toggler,
		show_video_toggler,
		currentSidebarState,
		currentVideoState,
		getVideoPlayer,
		schema,
		contentBlocks,
	} = props;
	let { id, slug } = useParams();
	let currentSidebar = currentSidebarState(id);
	let currentVideo = currentVideoState(id);

	const initialState = {
		displayToggle: location.pathname.includes('/watch-now/'),
		toggle: toggleVideoContainer,
		show_sidebar_toggle: show_sidebar_toggler,
		show_video_toggle: show_video_toggler,
		currentSidebarState: currentSidebar,
		currentVideoState: currentVideo,
		loading: true,
		videoPlayer: true,
		question_panel: false,
		question_tab_label: '',
		session_info_label: '',
		poll_tab_label: '',
		all_question_tab: false,
		my_question_tab: false,
		ask_question_tab: false,
		moderation: false,
		question_description_caption: '',
		poll_status: false,
	};

	const [state, setState] = useState(initialState);
	const [alert, setAlert] = useState({ alerts: [] });

	const closeAlert = (alertId) => {
		if (alert.alerts) {
			let alertMsg = alert.alerts
				.map(function (item) {
					return item.id;
				})
				.indexOf(alertId);
			if (alertMsg > -1) {
				alert.alerts.splice(alertMsg, 1);
				if (alertMsg > -1) setAlert({ ...alert, alerts: [...alert.alerts] });
			}
		}
	};

	useEffect(() => {
		const channel = pusherConfig().subscribe(getPusherChannel());
		channel.bind(`${schema}-send-alert`, (data) => {
			if (data.videoSessionId === parseInt(id)) {
				setAlert((prevState) => ({
					alerts: [...prevState.alerts, data],
				}));
			}
		});
		return () => channel.unbind(`${schema}-send-alert`);
	}, []);

	const callbackSucces = (data) => {
		const {
			video_player,
			question_panel,
			question_tab_label,
			session_info_label,
			agenda_info_label,
			poll_tab_label,
			all_question_tab,
			my_question_tab,
			ask_question_tab,
			agenda_tab,
			moderation,
			question_description_caption,
			poll_status,
		} = data;

		setState({
			...state,
			loading: false,
			videoPlayer: video_player,
			question_panel: question_panel,
			question_tab_label: question_tab_label,
			session_info_label: session_info_label,
			agenda_info_label: agenda_info_label,
			poll_tab_label: poll_tab_label,
			all_question_tab: all_question_tab,
			my_question_tab: my_question_tab,
			ask_question_tab: ask_question_tab,
			agenda_tab: agenda_tab,
			moderation: moderation,
			question_description_caption: question_description_caption,
			poll_status: poll_status,
		});
	};

	const callbackError = (e) => {
		setState({ ...state, loading: false });
		if (e.errorCode === 401) {
			ErrorToast(e.message, 3000);
			props.history.push(`/${slug}/info`);
		}
	};

	useEffect(() => {
		getVideoPlayer(id, slug, callbackSucces, callbackError);
	}, [id, slug]);

	const handleChange = (nextChecked) => {
		setState({
			...state,
			toggle: nextChecked,
			currentSidebarState: nextChecked,
		});
		toggleSidebar(id);
	};

	const handleVideoChange = (nextChecked) => {
		setState({
			...state,
			currentVideoState: nextChecked,
		});
		toggleVideo(id, nextChecked);
	};
	useEffect(() => {
		setState({
			...state,
			currentSidebarState: currentSidebar,
			currentVideoState: currentVideo,
		});
	}, [currentSidebar, currentVideo]);

	const OrderofPersentation = () => {
		return (
			<>
				{contentBlocks && contentBlocks.tab_heading && (
					<title>{contentBlocks.tab_heading}</title>
				)}
				{eventSlug() === slug ? (
					<div className="presentations mt-4">
						<div className="presentation">
							<div className="presentation-title">Official welcome</div>
							<div className="presentation-info">
								<p>Welcome to Country</p>
								<p>Welcome from HESTA and AIA</p>
							</div>
						</div>
						<div className="presentation">
							<div className="presentation-title">Awards</div>
							<div className="presentation-info">
								<p>Individual Distinction</p>
								<p>Outstanding Organisation</p>
								<p>Team Innovation</p>
							</div>
						</div>
						<div className="presentation">
							<div className="presentation-info">
								{/* <p>
									<a
										href="//hesta.com.au/ECECreport21"
										className="btn workforce-report mt-4"
										target="_blank"
										rel="noreferrer"
									>
										Download the program
									</a>
								</p> */}
							</div>
						</div>
					</div>
				) : (
					<div className="presentations mt-4">
						<div className="presentation">
							<div className="presentation-title">Official welcome</div>
							<div className="presentation-info">
								<p>Welcome to Country</p>
								<p>Welcome from HESTA, ME and ECA</p>
							</div>
						</div>
						<div className="presentation">
							<div className="presentation-title">Awards</div>
							<div className="presentation-info">
								<p>Advancing Pedagogy {'&'} Practice</p>
								<p>Individual Leadership</p>
								<p>Outstanding Organisation</p>
							</div>
						</div>
						<div className="presentation">
							<div className="presentation-title">
								Early childhood education and care insights
							</div>
							<div className="presentation-info">
								<p>
									The pandemic has shown the critical role our early educators
									play in supporting our community. Our latest report offers
									insights that can help shape the sector
									{"'"}s future.
								</p>
								<p>
									<a
										href="//hesta.com.au/ECECreport21"
										className="btn workforce-report mt-4"
										target="_blank"
										rel="noreferrer"
									>
										Read the workforce report
									</a>
								</p>
							</div>
						</div>
					</div>
				)}
			</>
		);
	};

	return (
		<div className="theo-player-container">
			{state.displayToggle && (
				<Container className="wrapper-container">
					<Row>
						<Col>
							<div className="float-right toggle-switch">
								{show_sidebar_toggler && (
									<>
										<p>Side bar</p>
										<Switch
											checked={currentSidebarState(id)}
											onChange={handleChange}
											onColor="#86d3ff"
											onHandleColor="#2693e6"
											handleDiameter={20}
											uncheckedIcon={false}
											checkedIcon={false}
											boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
											activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
											height={15}
											width={43}
											className="react-switch custom-switch"
											id="material-switch"
										/>
									</>
								)}

								{show_video_toggler && (
									<>
										<p className="video-label">Video</p>
										<Switch
											checked={currentVideoState(id)}
											onChange={handleVideoChange}
											onColor="#86d3ff"
											onHandleColor="#2693e6"
											handleDiameter={20}
											uncheckedIcon={false}
											checkedIcon={false}
											boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
											activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
											height={15}
											width={43}
											className="react-switch custom-switch"
											id="material-switch"
										/>
									</>
								)}
							</div>
							<br />
						</Col>
					</Row>
				</Container>
			)}
			<Container>
				{alert.alerts &&
					alert.alerts.map(
						(alertMsg, index) =>
							alertMsg.alertPosition === 1 && (
								<AlertSignal key={index} data={alertMsg} close={closeAlert} />
							)
					)}
			</Container>
			<div className="video-player">
				<div
					className={`cover-container ${
						slug === eventSlug() && 'excellence-2022'
					}`}
				>
					{contentBlocks.header_img ? (
						<img
							src={contentBlocks.header_img}
							className="hesta-awards-banner"
						/>
					) : (
						<img
							src={getImageUrl(
								`/assets/images/HESTA-banner-early-childhood-awards.jpg`
							)}
						/>
					)}
				</div>
				<div className={`poster ${slug === eventSlug() && 'poster-2022'}`}>
					<Row className="personal-card">
						<Col md={12}>
							{!state.loading && state.videoPlayer ? (
								state.videoPlayer === 1 ? (
									<Suspense fallback={<div></div>}>
										<VideoJS
											data={alert.alerts.filter(function (item) {
												return item.alertPosition === 2;
											})}
											close={closeAlert}
										/>
									</Suspense>
								) : (
									<Suspense fallback={<div></div>}>
										<VideoJS
											data={alert.alerts.filter(function (item) {
												return item.alertPosition === 2;
											})}
											close={closeAlert}
										/>
									</Suspense>
								)
							) : (
								''
							)}
						</Col>
						<Col md={0} className={`${currentSidebarState(id) ? '' : 'hide'}`}>
							<SideDrawer
								displayQuestion={state.question_panel}
								questionLabel={state.question_tab_label}
								sessionLabel={state.session_info_label}
								agendaLabel={state.agenda_info_label}
								pollLabel={state.poll_tab_label}
								allQuestion={state.all_question_tab}
								myQuestion={state.my_question_tab}
								askQuestion={state.ask_question_tab}
								agendaTab={state.agenda_tab}
								moderation={state.moderation}
								question_description_caption={
									state.question_description_caption
								}
								pollStatus={state.poll_status}
							/>
						</Col>
					</Row>
				</div>
			</div>
			<Container className="wrapper-container">
				<Row className="social-media">
					<Col md={6}>
						{/* <a
							href={getImageUrl(
								eventSlug() !== slug
									? '/assets/files/HESTA_HECEC Awards_2021 Events Program.pdf'
									: '/assets/files/HESTA Impact Awards - 2022 Events Program (Web-ready).pdf'
							)}
							download
						>
							<Button>Download Program</Button>
						</a>
						<div className="mt-4">
							<p className="connect">connect {'&'} share</p>
							<Row className="mt-4">
								<Col md={1}>
									<Icon icon={facebookOfficial} />
								</Col>
								<Col md={11}>
									<p>Share your photos and congratulate our finalists</p>
									<p>
										<a
											href="https://www.facebook.com/HESTAAwards/"
											target="_blank"
											rel="noreferrer"
										>
											facebook.com/HESTAAwards
										</a>{' '}
										<b>#HESTAAwards</b>
									</p>
								</Col>
							</Row>
							<Row className="mt-4">
								<Col md={1}>
									<Icon icon={twitterSquare} />
								</Col>
								<Col md={11}>
									<p>Join the conversation</p>
									<p>
										<a
											href="https://twitter.com/HESTASuper"
											target="_blank"
											rel="noreferrer"
										>
											@HESTASuper
										</a>{' '}
										<b>#HESTAAwards</b>
									</p>
								</Col>
							</Row>
							<Row className="mt-4">
								<Col md={1}>
									<Icon icon={instagramIcon} />
								</Col>
								<Col md={11}>
									<p>Share your photos and congratulate our finalists</p>
									<p>
										<a
											href="https://www.instagram.com/hesta.super/"
											target="_blank"
											rel="noreferrer"
										>
											@hesta.super
										</a>{' '}
										<b>#HESTAAwards</b>
									</p>
								</Col>
							</Row>
						</div> */}
					</Col>
					<Col md={6} className="responsive-seperator">
						<Row>
							<Col md={12} sm={12}>
								<h4 className="order-presentation">Order of presentation</h4>
								<OrderofPersentation />
							</Col>
						</Row>
					</Col>
				</Row>
				<SystemInfo />
				{alert.alerts &&
					alert.alerts.map(
						(alertMsg, index) =>
							alertMsg.alertPosition === 3 && (
								<AlertSignal key={index} data={alertMsg} close={closeAlert} />
							)
					)}
			</Container>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		toggleVideoContainer: togglePosition(state),
		schema: getSchemaName(state),
		show_sidebar_toggler: sidebarToggler(state),
		show_video_toggler: videoToggler(state),
		currentSidebarState: (id) => getShowSideBarToggler(state, id),
		currentVideoState: (id) => getVideoToggler(state, id),
		contentBlocks: getContentBlock(state),
	};
};
const dispatchProps = {
	toggleSidebar,
	toggleVideo,
	getVideoPlayer,
};

export default connect(mapStateToProps, dispatchProps)(withRouter(Watch));
