import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import { allEvents } from 'actions/event';
import { getContentBlock } from 'selectors/event';
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import { getJustLogIn } from 'selectors/system';
import { isLoggedIn } from 'selectors/auth';
import { setJustLoggedIn } from 'actions/system';
import { getImageUrl, eventSlug } from 'helper/helper';

const Home = (props) => {
	const [state, setState] = useState({ events: [], error: '' });
	const [currentData, setCurrentData] = useState({
		register: '',
		slug: '',
	});

	const {
		contentBlocks,
		justLoggedIn,
		isLoggedIn,
		allEvents,
		setJustLoggedIn,
	} = props;

	function callbackSuccess(data) {
		let sortedData = data.sort(sortEvents);
		if (justLoggedIn) {
			// props.history.push(`/event/${sortedData[0].slug}/info`);
			setJustLoggedIn(false);
		}

		if (sortedData[0]) {
			setCurrentData({
				...currentData,
				slug: sortedData[0].slug,
				register: sortedData[0].registration,
			});
		}
		setState({ ...state, events: sortedData });
	}

	function callbackError(error) {
		setState({ ...state, error: error.message });
	}
	useEffect(() => {
		allEvents(callbackSuccess, callbackError);
	}, [justLoggedIn]);

	const sortEvents = (a, b) => {
		const bandA = a.id;
		const bandB = b.id;

		let comparison = 0;
		if (bandA > bandB) {
			comparison = -1;
		} else if (bandA < bandB) {
			comparison = 1;
		}
		return comparison;
	};

	const WelcomeText = () => {
		return (
			<Row className="welcome">
				<Col md={12}>
					<div
						dangerouslySetInnerHTML={{
							__html: contentBlocks.welcome_text,
						}}
					></div>
				</Col>
			</Row>
		);
	};
	const MainComponent = () => {
		return (
			<div className="welcome-info">
				<Row className="mt-5 excellence-awards excellence-2022">
					<div>
						<div className="welcome-img-container">
							{contentBlocks.header_img ? (
								<img
									src={contentBlocks.header_img}
									className="hesta-awards-banner"
								/>
							) : (
								<img
									className="hesta-awards-banner"
									src={getImageUrl(
										`/assets/images/HESTA-banner-early-childhood-awards.jpg`
									)}
								/>
							)}

							<div
								className={`card-info ${
									currentData.slug === eventSlug() && 'card-info-2022'
								}`}
							>
								<Row>
									<Col md={6}>
										{currentData.slug === eventSlug() ? (
											<div className="award-logo-container home-logo-cont">
												<img
													src={getImageUrl(
														`/assets/images/impact-awards-logo-white.png`
													)}
												/>
											</div>
										) : (
											<div className="award-logo-container">
												<img
													src={getImageUrl(
														`/assets/images/impact-awards-logo-white.png`
													)}
												/>
											</div>
										)}
									</Col>
									<Col md={6}>
										<div>
											<p className="ta-left">
												The HESTA Impact Awards recognise people working in
												health and community services who are committed to
												creating a better future.
											</p>
											{currentData.register && !isLoggedIn(currentData.slug) ? (
												<>
													{currentData.slug !== eventSlug() ? (
														<Link
															to={`/${currentData.slug}/register/${currentData.register}`}
														>
															<Button
																size="md"
																className="text-size mt-5 button-2022"
															>
																Register now
															</Button>
														</Link>
													) : (
														<a href="https://www.hesta.com.au/awards/impact-awards.html">
															<Button
																size="md"
																className="text-size mt-5 button-2022"
															>
																Watch the broadcast
															</Button>
														</a>
													)}
												</>
											) : (
												<Link to={`/${currentData.slug}/info`}>
													<Button
														size="md"
														className="text-size mt-5 button-2022"
													>
														Watch the broadcast
													</Button>
												</Link>
											)}
										</div>
									</Col>
								</Row>
							</div>
						</div>
					</div>
				</Row>
				{currentData.slug === eventSlug() ? (
					<Container className="wrapper-container">
						<WelcomeText />
					</Container>
				) : (
					<WelcomeText />
				)}
			</div>
		);
	};
	return (
		<>
			{currentData.slug === eventSlug() ? (
				<>
					<MainComponent />
				</>
			) : (
				<Container className="wrapper-container">
					<MainComponent />
				</Container>
			)}
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		isLoggedIn: (slug) => isLoggedIn(state, slug),
		contentBlocks: getContentBlock(state),
		justLoggedIn: getJustLogIn(state),
	};
};
const dispatchProps = {
	allEvents,
	setJustLoggedIn,
};

export default connect(mapStateToProps, dispatchProps)(Home);
