import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import SideAccordion from './SideAccordion';
import { getAgendaInformation } from 'selectors/session';

const Agenda = ({ info }) => {
	return (
		<p
			dangerouslySetInnerHTML={{
				__html: info,
			}}
		></p>
	);
};

const AgendaInformation = (props) => {
	const { label, agendaInformation } = props;
	const [state, setState] = useState('');
	let { slug, id } = useParams();

	useEffect(() => {
		setState(agendaInformation(id));
	}, [agendaInformation, id, slug]);

	return (
		<div className="mb-4">
			<SideAccordion
				icon={<FontAwesomeIcon icon={faCalendar} />}
				title={label}
				component={<Agenda info={state} />}
			/>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		agendaInformation: (id) => getAgendaInformation(state, id),
	};
};
const dispatchProps = {};

export default connect(mapStateToProps, dispatchProps)(AgendaInformation);
