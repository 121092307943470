import {
	UPDATE_EVENT_REDIRECT,
	UPDATE_EVENT_SLUG,
	SET_CONTENT_DATA,
	CLEAR_CONTENT_DATA,
} from 'actions/types';

const defaultEventState = {
	redirectToVideoSession: false,
	currentEventSlug: '',
	redirectTo: '',
	header_img: '',
	has_registration: '',
	registration_slug: '',
	footer_text: '',
	welcome_text: '',
	login_modes: '',
	otp_input_page: '',
	otp_receive_page: '',
	sign_in_text: '',
	otp_receive_page_footer: '',
	agenda_header: '',
	above_my_session: '',
	below_my_session: '',
	navigation_background_color: '',
	navigation_text_color: '',
	navigation_font_size: '',
	navigation_active_color: '',
	date_time_format: '',
	navigation_active_text_color: '',
	info_heading: '',
	tab_heading: '',
	registration_page: '',
};

export const event = (state = defaultEventState, action) => {
	switch (action.type) {
		case UPDATE_EVENT_REDIRECT: {
			const { redirect_to_video_session, slug, video_session } = action.data;
			return {
				...state,
				redirectToVideoSession: redirect_to_video_session,
				currentEventSlug: slug,
				redirectTo: video_session,
			};
		}
		case UPDATE_EVENT_SLUG: {
			const { slug } = action;
			return {
				...state,
				currentEventSlug: slug,
			};
		}
		case SET_CONTENT_DATA: {
			const { data } = action.data;
			if (data)
				return {
					...state,
					header_img: data.header_img,
					has_registration: data.has_registration,
					registration_slug: data.registration_slug,
					footer_text: data.footer_text,
					welcome_text: data.welcome_text,
					login_modes: data.login_modes,
					otp_input_page: data.otp_input_page,
					otp_receive_page: data.otp_receive_page,
					sign_in_text: data.sign_in_text,
					otp_receive_page_footer: data.otp_receive_page_footer,
					agenda_header: data.agenda_header,
					above_my_session: data.above_my_session,
					below_my_session: data.below_my_session,
					navigation_background_color: data.navigation_background_color,
					navigation_text_color: data.navigation_text_color,
					navigation_font_size: data.navigation_font_size,
					navigation_active_color: data.navigation_active_color,
					date_time_format: data.date_time_format,
					navigation_active_text_color: data.navigation_active_text_color,
					info_heading: data.info_heading,
					tab_heading: data.tab_heading,
					registration_page: data.registration_page,
				};
			else return { ...state };
		}
		case CLEAR_CONTENT_DATA: {
			return {
				...state,
				header_img: '',
				has_registration: '',
				registration_slug: '',
				footer_text: '',
				welcome_text: '',
				login_modes: '',
				otp_input_page: '',
				otp_receive_page: '',
				sign_in_text: '',
				otp_receive_page_footer: '',
				agenda_header: '',
				above_my_session: '',
				below_my_session: '',
				navigation_background_color: '',
				navigation_text_color: '',
				navigation_font_size: '',
				navigation_active_color: '',
				date_time_format: '',
				navigation_active_text_color: '',
				info_heading: '',
				tab_heading: '',
			};
		}
		default:
			return state;
	}
};
