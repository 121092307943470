import Pusher from 'pusher-js';
import { getPusherKey, getPusherCluster } from 'config/Config';

var newPusher;
export const pusherConfig = () => {
	if (newPusher) return newPusher;
	const pusher = new Pusher(getPusherKey(), {
		cluster: getPusherCluster(),
		encrypted: true,
	});
	newPusher = pusher;
	return newPusher;
};
