import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Icon } from '@iconify/react';
import envelopeO from '@iconify-icons/fa/envelope-o';
import facebookIcon from '@iconify-icons/fa/facebook';
import linkedinIcon from '@iconify-icons/fa/linkedin';
import youtubePlay from '@iconify-icons/fa/youtube-play';
import twitterIcon from '@iconify-icons/fa/twitter';
import phoneIcon from '@iconify-icons/akar-icons/phone';
import chatboxOutline from '@iconify-icons/ion/chatbox-outline';
import { getImageUrl, eventSlug } from 'helper/helper';
import { getContentBlock } from 'selectors/event';

const Footer = (props) => {
	const { contentBlocks, slug } = props;

	const NotForIndex = () => {
		return (
			<>
				<div className="footer pb-5">
					<Row className="responsive-gap">
						<Col md={6} lg={3} sm={12}>
							<p className="footer-flex">
								<Icon icon={envelopeO} className="envelop" />
								<span className="pl-1">HESTA Awards Program,</span>
							</p>
							<p>PO Box 615,</p>
							<p>Carlton South VIC 3053</p>
						</Col>
						<Col md={6} lg={3} sm={12}>
							<p className="footer-flex phone">
								<Icon icon={phoneIcon} />
								<span className="pl-1"> 03 8660 1600</span>
							</p>
						</Col>
						<Col md={6} lg={3} sm={12}>
							<p className="footer-flex email">
								<Icon icon={chatboxOutline} className="phoneIcon" />
								<a
									href="mailto:awards@hesta.com.au"
									className="ml-1"
									target="_blank"
									rel="noreferrer"
								>
									Email us
								</a>
							</p>
						</Col>
						<Col md={6} lg={3} sm={12}>
							<div className="flex social-media">
								<a
									href="https://www.facebook.com/HESTAAwards/"
									target="_blank"
									rel="noreferrer"
								>
									<Icon icon={facebookIcon} />
								</a>
								<a
									href="https://www.linkedin.com/company/hesta-superannuation"
									target="_blank"
									rel="noreferrer"
								>
									<Icon icon={linkedinIcon} />
								</a>
								<a
									href="https://www.youtube.com/user/HESTAsuperfund"
									target="_blank"
									rel="noreferrer"
								>
									<Icon icon={youtubePlay} />
								</a>
								<a
									href="https://twitter.com/HESTASuper"
									target="_blank"
									rel="noreferrer"
								>
									<Icon icon={twitterIcon} />
								</a>
							</div>
						</Col>
					</Row>
				</div>
				<div className="footer-seperator"></div>
			</>
		);
	};
	console.log(location.pathname.includes(`${eventSlug()}`), '>>>');
	return (
		<div className="footer-container">
			{location.pathname !== '/' &&
				!location.pathname.includes(`${eventSlug()}`) && <NotForIndex />}
			<div className="footer bottom-footer pt-5">
				<Row>
					<Col md={6} sm={4} lg={3}>
						<div>
							<p>PROUDLY PRESENTED BY</p>
							<div className="hesta-award-logo-cont">
								<img
									className="mt-3"
									src={getImageUrl(
										`/assets/images/HESTA_Logo_Industry-LockUp_AllWhite_992x250h (1).png`
									)}
								/>
							</div>
						</div>
					</Col>
					{location.pathname !== '/' &&
						!location.pathname.includes(`${eventSlug()}`) && (
							<Col md={6} sm={4} lg={3} className="footer-responsive-gap">
								<div>
									<p>IN PARTNERSHIP WITH</p>
									<div className="hesta-award-logo-cont">
										<img
											className="mt-3"
											src={getImageUrl(
												`/assets/images/ECA Logo small8bit25mm.png`
											)}
										/>
									</div>
								</div>
							</Col>
						)}
					<Col md={6} sm={4} lg={3} className="footer-responsive-gap">
						<div className="mt-responsive-gap">
							<p>PROUDLY SUPPORTED BY</p>
							{location.pathname !== '/' &&
							!location.pathname.includes(`${eventSlug()}`) ? (
								<div className="ME-logo-container">
									<img
										className="mt-3"
										src={getImageUrl(
											`/assets/images/ME-Logo-Rev-transparent_72dpi.png`
										)}
									/>
								</div>
							) : (
								<div className="ME-logo-container logo-cont-2022">
									<img
										className="mt-3"
										src={getImageUrl(`/assets/images/sponsors-footer-AIA.png`)}
									/>
								</div>
							)}
						</div>
					</Col>
					{(location.pathname === '/' ||
						location.pathname.includes(`${eventSlug()}`)) && (
						<Col md={6} sm={4} lg={6} className="footer-responsive-gap">
							<Row className="responsive-gap gap-2022">
								<Col md={6} lg={4} sm={12} style={{ padding: '0' }}>
									<p className="footer-flex phone">
										<Icon icon={phoneIcon} />
										<span className="pl-1"> 03 8660 1600</span>
									</p>
								</Col>
								<Col md={6} lg={4} sm={12}>
									<p className="footer-flex email">
										<Icon icon={chatboxOutline} className="phoneIcon" />
										<a
											href="mailto:awards@hesta.com.au"
											className="ml-1"
											target="_blank"
											rel="noreferrer"
											style={{ fontSize: '1.2rem' }}
										>
											Email us
										</a>
									</p>
								</Col>
								<Col md={6} lg={4} sm={12} className="mb-2">
									<div className="flex social-media">
										<a
											href="https://www.facebook.com/HESTAAwards/"
											target="_blank"
											rel="noreferrer"
										>
											<Icon icon={facebookIcon} />
										</a>
										<a
											href="https://www.linkedin.com/company/hesta-superannuation"
											target="_blank"
											rel="noreferrer"
										>
											<Icon icon={linkedinIcon} />
										</a>
										<a
											href="https://www.youtube.com/user/HESTAsuperfund"
											target="_blank"
											rel="noreferrer"
										>
											<Icon icon={youtubePlay} />
										</a>
										<a
											href="https://twitter.com/HESTASuper"
											target="_blank"
											rel="noreferrer"
										>
											<Icon icon={twitterIcon} />
										</a>
									</div>
								</Col>
							</Row>
						</Col>
					)}
				</Row>
			</div>
			<div className="footer bottom-footer mt-5">
				<div className="flex">
					<a
						href="https://www.hesta.com.au/content/hesta/awards/hesta-awards-
privacy-policy.html"
						target="_blank"
						rel="noreferrer"
					>
						HESTA Awards Privacy Policy
					</a>
					<a
						href="https://www.hesta.com.au/content/hesta/awards/awards-terms-and-
conditions.html"
						target="_blank"
						rel="noreferrer"
					>
						Terms {'&'} Conditions
					</a>
					{location.pathname !== '/' &&
					!location.pathname.includes(`${eventSlug()}`) ? (
						<a
							href="https://www.hesta.com.au/awards/childhood-education-awards/nomination-tc-early-childhood-awards.html"
							target="_blank"
							rel="noreferrer"
						>
							Nomination Terms {'&'} Conditions
						</a>
					) : (
						<a
							href="https://www.hesta.com.au/awards/impact-awards/nomination-tc-impact-awards.html"
							target="_blank"
							rel="noreferrer"
						>
							Nomination Terms {'&'} Conditions
						</a>
					)}
				</div>
			</div>
			<div className="footer bottom-footer mt-2">
				<p>
					&copy; Issued by H.E.S.T. Australia Ltd ABN 66 006 818 695 AFSL No.
					235249 Trustee of Health Employees Superannuation Trust Australia
					(HESTA) ABN 64 971 749 321.
				</p>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		contentBlocks: getContentBlock(state),
	};
};
const dispatchProps = {};

export default connect(mapStateToProps, dispatchProps)(withRouter(Footer));
