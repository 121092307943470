import React from 'react';
import Form from 'react-bootstrap/Form';

import {
	TEXT,
	EMAIL,
	RADIO_MULTIPLE,
	NUMBER,
	TEXTAREA,
	CONFIRM_EMAIL,
	SELECT,
	CHECKBOX,
	CHECKBOX_MULTIPLE,
} from 'constants/Fields';
import { splitChoices } from 'util/helper';

const Fields = ({ data, change, submitForm, registrationsActive }) => {
	switch (data.field_type) {
		case TEXT: {
			return (
				<>
					<Form.Control
						placeholder={
							data.placeholder_text ? data.placeholder_text : data.label
						}
						name={data.id}
						required={data.required ? true : false}
						onChange={(e) => change(e)}
						disabled={!registrationsActive}
					/>
					{data.help_text && <small>{data.help_text}</small>}
				</>
			);
		}
		case CONFIRM_EMAIL:
		case EMAIL: {
			return (
				<>
					<Form.Control
						type="email"
						name={data.id}
						placeholder={
							data.placeholder_text ? data.placeholder_text : data.label
						}
						onChange={(e) => change(e)}
						required={data.required ? true : false}
						disabled={!registrationsActive}
					/>
					{data.help_text && <small>{data.help_text}</small>}
				</>
			);
		}
		case RADIO_MULTIPLE: {
			let choices = splitChoices(data.choices);
			return (
				<>
					<Form.Label>
						{data.label} {data.required && '*'}
					</Form.Label>
					<div className="mb-3 flex-radio">
						{choices.map((choice, index) => {
							return (
								<Form.Check
									inline
									name={data.id}
									key={index}
									label={choice}
									value={choice.replace(/^\s+|\s+$/g, '').toLowerCase()}
									onChange={(e) => change(e)}
									type="radio"
									required={data.required ? true : false}
									disabled={!registrationsActive}
								/>
							);
						})}
					</div>
					{data.help_text && <small>{data.help_text}</small>}
				</>
			);
		}
		case NUMBER: {
			return (
				<>
					<Form.Label>
						{data.label} {data.required && '*'}
					</Form.Label>
					<Form.Control
						type="number"
						name={data.id}
						placeholder={
							data.placeholder_text ? data.placeholder_text : data.label
						}
						onChange={(e) => change(e)}
						required={data.required ? true : false}
						disabled={!registrationsActive}
					/>
					{data.help_text && <small>{data.help_text}</small>}
				</>
			);
		}
		case TEXTAREA: {
			return (
				<>
					<Form.Group controlId="exampleForm.ControlTextarea1">
						<Form.Label>
							{data.label} {data.required && '*'}
						</Form.Label>
						<Form.Control
							as="textarea"
							name={data.id}
							rows={3}
							onChange={(e) => change(e)}
							required={data.required}
							disabled={!registrationsActive}
						/>
						{data.help_text && <small>{data.help_text}</small>}
					</Form.Group>
				</>
			);
		}
		case SELECT: {
			let choices = splitChoices(data.choices);
			return (
				<>
					<Form.Group controlId="exampleForm.ControlSelect1">
						<Form.Label>
							{data.label} {data.required && '*'}
						</Form.Label>
						<Form.Control
							as="select"
							name={data.id}
							onChange={(e) => change(e)}
							disabled={!registrationsActive}
						>
							<option selected disabled>
								Select {data.label}
							</option>
							{choices.map((choice, index) => (
								<option value={choice} key={index}>
									{choice}
								</option>
							))}
						</Form.Control>
						{data.help_text && <small>{data.help_text}</small>}
					</Form.Group>
				</>
			);
		}
		case CHECKBOX: {
			return (
				<>
					<Form.Check
						label={data.label}
						name={data.id}
						onChange={(e) => change(e)}
						required={data.required}
						value={submitForm && submitForm[data.id] ? 'No' : 'Yes'}
						disabled={!registrationsActive}
					/>
					{data.help_text && <small>{data.help_text}</small>}
				</>
			);
		}
		case CHECKBOX_MULTIPLE: {
			let choices = splitChoices(data.choices);
			return (
				<Form.Group controlId="exampleForm.ControlSelect1">
					<Form.Label>
						{data.label} {data.required && '*'}
					</Form.Label>

					{choices.map((choice, index) => (
						<Form.Check
							label={choice}
							key={index}
							name={`multi_check-${choice}-${data.id}`}
							value={choice}
							onChange={(e) => change(e)}
							disabled={!registrationsActive}
						/>
					))}
					{data.help_text && <small>{data.help_text}</small>}
				</Form.Group>
			);
		}
		default: {
			return (
				<>
					<Form.Label>
						{data.label} {data.required && '*'}
					</Form.Label>
					<Form.Control
						placeholder={data.placeholder_text}
						required={data.required ? true : false}
						disabled={!registrationsActive}
					/>
				</>
			);
		}
	}
};

export default Fields;
