import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { eventRegistration, submitEventRegistration } from 'actions/event';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Fields from './Fields';
import Spinner from 'react-bootstrap/Spinner';
import Button from 'react-bootstrap/Button';
import { isLoggedIn } from 'selectors/auth';
import { MULTIPLE_SESSION, EMAIL, CONFIRM_EMAIL } from 'constants/Fields';
import { validateFormWithoutSession } from 'util/Validator';
import { ErrorToast, SuccessToast } from 'components/ToastMessage/Toast';
import { getContentBlock } from 'selectors/event';
import { getImageUrl, eventSlug } from 'helper/helper';
import { emailOnly } from 'actions/auth';

const Registration = (props) => {
	const initial = {
		loading: true,
		formWithoutSession: [],
		error: '',
		submitForm: {},
		reload: false,
		emailStack: [],
		default_select: true,
		submitting: false,
		selectedSessions: [],
		defaultFirstTime: true,
	};
	const [state, setState] = useState(initial);
	let { slug, event_slug } = useParams();
	const { contentBlocks, eventRegistration } = props;
	if (props.isLoggedIn(event_slug)) {
		props.history.push(`/${event_slug}/info`);
	}

	const handleChange = (e) => {
		let name = e.target.name;
		let split_name = name.split('-');

		// If > 1, then multiple session field
		if (split_name.length > 1) {
			if (state.submitForm[split_name[2]]) {
				let hasSessions = state.submitForm[split_name[2]].indexOf(
					split_name[1]
				);
				if (hasSessions > -1) {
					// Deselect
					state.submitForm[split_name[2]].splice(hasSessions, 1);
				} else {
					// Select
					state.submitForm[split_name[2]].push(split_name[1]);
				}
			} else {
				state.submitForm[split_name[2]] = [split_name[1]];
			}
		} else {
			state.submitForm[`${e.target.name}`] = e.target.value;
		}
		setState({
			...state,
			submitForm: { ...state.submitForm },
		});
	};

	const callbackSuccess = (data) => {
		setState({ ...state, ...data, loading: false });
	};
	const callbackError = () => {
		setState({ ...state, error: 'Event does not exists.', loading: false });
	};

	useEffect(() => {
		eventRegistration(slug, callbackSuccess, callbackError);
	}, [slug, state.reload === true]);

	useEffect(() => {
		if (state.form_fields) {
			var formWithoutSession = state.form_fields.filter(function (data) {
				return data.field_type != MULTIPLE_SESSION;
			});

			let selectedSessions = [];

			var emailStack = formWithoutSession.filter(function (data) {
				return data.field_type === EMAIL || data.field_type === CONFIRM_EMAIL;
			});

			setState({
				...state,
				formWithoutSession: formWithoutSession,
				emailStack: emailStack,
				selectedSessions: selectedSessions,
			});
		}
	}, [state.form_fields]);

	const submitSuccess = (responseData) => {
		const { login, message } = responseData;
		const { slug } = responseData.data;
		if (login) {
			props.history.push(`/${slug}/info`);
		} else {
			setState(initial);
			setState({ ...state, reload: true });
		}
		SuccessToast(message, 3000);
		setState({ ...state, submitting: false });
	};

	const autoLoginSuccess = () => {
		setState({ ...state, submitting: false });
	};

	const autoLoginError = () => {
		ErrorToast(
			'This email address has already been registered. Please see your registration confirmation email for access link.'
		);
	};

	const submitError = (e) => {
		console.log(e);
		if (
			e.message[0] &&
			e.message[0].error === 'User with this email already exists'
		) {
			const { emailOnly } = props;
			emailOnly(
				state.submitForm[state.emailStack[0].id].toLowerCase(),
				event_slug,
				'recaptchaValue',
				autoLoginSuccess,
				autoLoginError
			);
		} else {
			ErrorToast(e.message[0].error);
		}
		setState({ ...state, submitting: false });
	};
	const handleSubmit = (e) => {
		e.preventDefault();
		setState({ ...state, submitting: true });
		if (state.emailStack.length > 1) {
			if (
				state.submitForm[state.emailStack[0].id] !==
				state.submitForm[state.emailStack[1].id]
			) {
				ErrorToast('Two email does not match');
				setState({ ...state, submitting: false });
				return;
			}
		}
		let error = validateFormWithoutSession(
			state.formWithoutSession,
			state.submitForm
		);
		if (error.length <= 0) {
			const { submitEventRegistration } = props;
			submitEventRegistration(
				slug,
				state.submitForm,
				state.selectedSessions,
				submitSuccess,
				submitError
			);
		}
	};

	const checkSlug = () => {
		return location.pathname.includes(`${eventSlug()}`);
	};
	return (
		<div className={`event-details ${checkSlug() && 'event-details-2022'}`}>
			{contentBlocks && contentBlocks.tab_heading && (
				<Helmet>
					<title>{contentBlocks.tab_heading}</title>
				</Helmet>
			)}
			{state.loading ? (
				<h4>Loading....</h4>
			) : (
				state.formWithoutSession && (
					<div className="welcome-info content">
						<div
							className="event-description break-word"
							dangerouslySetInnerHTML={{
								__html: state.event_description,
							}}
						></div>
						<Row
							className={`mt-5 excellence-awards ${
								checkSlug() && 'excellence-2022'
							}`}
						>
							<div>
								<div className="welcome-img-container">
									{contentBlocks.header_img ? (
										<img
											src={contentBlocks.header_img}
											className="hesta-awards-banner"
										/>
									) : (
										<img
											className="hesta-awards-banner"
											src={getImageUrl(
												`/assets/images/HESTA-banner-early-childhood-awards.jpg`
											)}
										/>
									)}
									<div className="card-info card-padding reg-container">
										<Row>
											<Col md={4}>
												{checkSlug() ? (
													<div className="award-logo-container home-logo-cont">
														<img
															src={getImageUrl(
																`/assets/images/impact-awards-logo-white.png`
															)}
														/>
													</div>
												) : (
													<div className="award-logo-container">
														<img
															src={getImageUrl(
																`/assets/images/HESTA AWARDS_EarlyChildhood LockUp_RGB_White_PNG_Transparent Background.png`
															)}
														/>
													</div>
												)}
											</Col>
											<Col md={8} className="responsive-gap-form">
												<Form onSubmit={(e) => handleSubmit(e)}>
													<h4 className="text-center">Register here</h4>
													<p>
														{checkSlug()
															? 'Register for the HESTA Impact Awards'
															: `Register for the HESTA Early Childhood Education ${'&'}
															Care Awards`}
													</p>
													<div className="form-grid">
														{state.formWithoutSession.map((data, index) => (
															<div key={index}>
																<Fields
																	data={data}
																	submitForm={state.submitForm}
																	change={handleChange}
																	registrationsActive={
																		state.registrations_active
																	}
																/>
																{state.formWithoutSession && index === 2 && (
																	<div className="mt-3 float-right w-100">
																		<Button
																			type="submit"
																			size="sm"
																			variant="dark mx-auto"
																			className="register w-100"
																		>
																			{state.submitting ? (
																				<>
																					<Spinner
																						animation="border"
																						size="sm"
																					/>{' '}
																					Submitting
																				</>
																			) : (
																				state.button_text
																			)}
																		</Button>
																	</div>
																)}
															</div>
														))}
													</div>
													<p className="privacy-policy mt-4">
														The information you submit will be collected and
														used in accordance with the HESTA Awards{' '}
														<a
															href="https://www.hesta.com.au/content/hesta/awards/hesta-awards-privacy-policy.html"
															target="_blank"
															className="white"
															rel="noreferrer"
														>
															Privacy Policy
														</a>
													</p>
												</Form>
											</Col>
										</Row>
									</div>
								</div>
							</div>
							<Container className="wrapper-container">
								{contentBlocks.registration_page && (
									<Row>
										<Col md={12}>
											<div
												className="event-description break-word"
												dangerouslySetInnerHTML={{
													__html: contentBlocks.registration_page,
												}}
											></div>
										</Col>
									</Row>
								)}
							</Container>
						</Row>
					</div>
				)
			)}
			{state.error && <div className="error">{state.error}</div>}
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		isLoggedIn: (slug) => isLoggedIn(state, slug),
		contentBlocks: getContentBlock(state),
	};
};
const dispatchProps = {
	eventRegistration,
	submitEventRegistration,
	emailOnly,
};

export default connect(
	mapStateToProps,
	dispatchProps
)(withRouter(Registration));
