import { httpService } from 'services/HttpServices';
import { getEndPoint } from 'config/Config';
import EventHttpService from 'services/EventHttpService';

export const slugVerify = (slug) => {
	const completePath = getEndPoint() + '/sessions/slug-verify/';
	const payload = { slug: slug };
	return httpService.request('post', completePath, payload);
};

export const allEvents = () => {
	const completePath = getEndPoint() + '/sessions/events/';
	return httpService.request('get', completePath);
};

export const eventInformation = (slug) => {
	const completePath = getEndPoint() + `/sessions/events/${slug}/`;
	return EventHttpService.get(completePath, null, slug);
};

export const eventRegistration = (slug) => {
	const completePath = getEndPoint() + `/forms/get-form/${slug}/`;
	return httpService.request('get', completePath, {});
};

export const submitEventRegistration = (slug, submitForm, selectedSessions) => {
	const completePath = getEndPoint() + `/forms/create-data/`;
	const payload = {
		slug: slug,
		data: submitForm,
		selectedSessions: selectedSessions,
	};
	return httpService.request('post', completePath, payload);
};

export const firstEvent = () => {
	const completePath = getEndPoint() + `/sessions/first-event-slug`;
	return httpService.request('get', completePath, {});
};

export const getVideoPlayer = (id, slug) => {
	const completePath = getEndPoint() + `/sessions/get-video-player/${id}/`;
	return EventHttpService.get(completePath, null, slug);
};

export const getContentBlock = (slug) => {
	const completePath = getEndPoint() + `/content-block/event/${slug}`;
	return httpService.request('get', completePath, {});
};

export const getFirstVideoSessions = () => {
	const completePath = getEndPoint() + '/sessions/all-video-sessions';
	return httpService.request('get', completePath, {});
};

export const getForgotLink = (email) => {
	const completePath = getEndPoint() + '/forgot-link';
	const payload = { email: email.toLowerCase() };
	return httpService.request('post', completePath, payload);
};
