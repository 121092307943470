import * as allTypes from './types';

// Reducers
export function toggleSidebar() {
	return {
		type: allTypes.UPDATE_SYSTEM,
	};
}

// Reducers
export function toggleVideo() {
	return {
		type: allTypes.UPDATE_TOGGLE_VIDEO,
	};
}

export function updateSystem(
	sidebar_toggle,
	video_toggle,
	header_bg,
	footer_bg,
	body_dynamic
) {
	return {
		type: allTypes.UPDATE_SYSTEM_TOGGLE,
		sidebar_toggle,
		video_toggle,
		header_bg,
		footer_bg,
		body_dynamic,
	};
}

export function getGoogleId(callbackSuccess, callbackError) {
	return {
		type: allTypes.GET_GOOGLE_ID,
		callbackSuccess,
		callbackError,
	};
}

export function updateGoogleId(tag) {
	return {
		type: allTypes.UPDATE_GOOGLE_ID,
		tag,
	};
}

export function upGoogleId(googleId) {
	return {
		type: allTypes.UP_GOOGLE_ID,
		googleId,
	};
}

export function setJustLoggedIn(status) {
	return {
		type: allTypes.JUST_LOGGED_IN,
		status: status,
	};
}
