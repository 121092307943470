export const getImageUrl = (uri) => {
	return process.env.PUBLIC_URL + uri;
};

export const reloadPage = () => {
	window.location.reload();
};

export const generateRandomHash = (HASHLENGTH) => {
	const characters = '0123456789ABCDEFGHIJKLMNPQRSTUVWXYZ';
	let string = '';
	for (let i = 0; i <= HASHLENGTH; i++) {
		string += characters[Math.floor(Math.random() * characters.length)];
	}
	return string;
};

export const eventSlug = () => {
	return 'impact-2022';
};
