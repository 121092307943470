import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useRouteMatch } from 'react-router-dom';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { withRouter } from 'react-router-dom';
import { toggleSidebar, toggleVideo } from 'actions/system';
import { getCurrentEventSlug } from 'selectors/event';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { isLoggedIn } from 'selectors/auth';
import { authLogoutRequest } from 'actions/auth';
import { firstEvent } from 'actions/event';
import { getContentBlock, clearContentData } from 'actions/event';
import { togglePosition } from 'selectors/system';
import { Helmet } from 'react-helmet';
import { getImageUrl, eventSlug } from 'helper/helper';

const Navigation = (props) => {
	let {
		location,
		currentSlug,
		isLoggedIn,
		currentSidebarPosition,
		firstEvent,
	} = props;
	const { getContentBlock, clearContentData } = props;

	const initial = {
		toggle: currentSidebarPosition,
		displayToggle: location.pathname.includes('/watch-now/'),
		displayLogoutButton: false,
		slug: eventSlug() || '',
		loginSlug: '',
		registration: '',
		displayRegister: false,
		loading: true,
	};
	const [state, setState] = useState(initial);

	const callbackSuccess = (data) => {
		if (data && data.data) {
			let register =
				(data.data.has_registration &&
					data.data.registration_slug &&
					state.slug) ||
				false;

			setState({
				...state,
				...data.data,
				displayRegister: register,
				loading: false,
			});
		} else {
			setState({ ...state, loading: false });
		}
	};

	const callbackError = (data) => {
		setState({
			...state,
			...data.data,
		});
	};

	const eventSuccess = (data) => {
		const { slug, registration } = data[0] || '';
		if (slug)
			setState({
				...state,
				slug: slug,
				loginSlug: slug,
				registration: registration,
			});
	};

	useEffect(() => {
		let isMounted = true;
		if (isMounted) {
			if (state.slug) {
				getContentBlock(state.slug, callbackSuccess, callbackError);
			} else {
				setState(initial);
				clearContentData();
				firstEvent(eventSuccess);
			}
		}

		return () => {
			isMounted = false;
		};
	}, [state.slug]);

	const logoutSuccess = () => {
		toast.success('Logout Success.', {
			position: 'bottom-right',
			autoClose: 5000,
			hideProgressBar: true,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
		});
	};
	const logoutError = () => {
		toast.error('Something went wrong.', {
			position: 'bottom-right',
			autoClose: 5000,
			hideProgressBar: true,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
		});
	};

	const logout = () => {
		if (!currentSlug) return '';
		const { authLogoutRequest } = props;
		authLogoutRequest(currentSlug, logoutSuccess, logoutError);
	};

	return (
		<Container className="wrapper-container">
			{(state.navigation_background_color ||
				state.navigation_text_color ||
				state.navigation_font_size ||
				state.navigation_active_color) && (
				<style>{`.navbar, #responsive-navbar-nav{\
					background: ${state.navigation_background_color} !important;\
				}
				#responsive-navbar-nav .nav-link{
					color: ${state.navigation_text_color} !important;
					font-size: ${state.navigation_font_size} !important;
				}
				#responsive-navbar-nav .nav-active{
					background: ${state.navigation_active_color} !important;
					color: ${state.navigation_active_text_color} !important;
				}
				`}</style>
			)}
			{state.tab_heading && (
				<Helmet>
					<meta name="description" content={state.tab_heading} />
					<title>{state.tab_heading}</title>
				</Helmet>
			)}
			{/* <img
				src={state.header_img ? state.header_img : ``}
				className="banner-img header-img"
			/> */}
			<Row className="header">
				<Col md={6}>
					<a
						href="//www.hesta.com.au/awards.html"
						target="_blank"
						rel="noreferrer"
					>
						<div className="logo-container">
							<img
								src={getImageUrl(
									`/assets/images/HESTA AWARDS_Logo_MONO_Purple RGB 72dpi.png`
								)}
								alt={`Hesta Awards`}
							/>
						</div>
					</a>
				</Col>
				<Col md={6}>
					<a
						href="//www.hesta.com.au/members.html"
						className="float-right sub-title header-text"
						target="_blank"
						rel="noreferrer"
					>
						HESTA SITE <FontAwesomeIcon icon={faExternalLinkAlt} />
					</a>
				</Col>
			</Row>

			{isLoggedIn(currentSlug) && (
				<Navbar collapseOnSelect expand="lg">
					<Navbar.Toggle aria-controls="responsive-navbar-nav" />
					<Navbar.Collapse id="responsive-navbar-nav">
						<Nav className="mr-auto"></Nav>
						<Nav>
							<Nav.Link className="nav-links logout" onClick={logout}>
								Logout
							</Nav.Link>
						</Nav>
					</Navbar.Collapse>
				</Navbar>
			)}
		</Container>
	);
};

const mapStateToProps = (state) => {
	return {
		currentSidebarPosition: togglePosition(state),
		currentSlug: getCurrentEventSlug(state),
		isLoggedIn: (slug) => isLoggedIn(state, slug),
	};
};

const dispatchProps = {
	toggleSidebar,
	toggleVideo,
	authLogoutRequest,
	firstEvent,
	getContentBlock,
	clearContentData,
};

export default connect(mapStateToProps, dispatchProps)(withRouter(Navigation));
